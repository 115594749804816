<template>
  <div class="company-management-table">
    <v-btn
      align="right"
      v-if="selected.length > 0"
      color="primary"
      dark
      v-on:click="removeSelected"
      style="margin-bottom: 10px;"
    >
      Remove selected
    </v-btn>
    <v-app>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="$store.getters['admin/companies']"
        :server-items-length="$store.getters['admin/companiesTotal']"
        :items-per-page.sync="params.per_page"
        :page.sync="params.page"
        @update:page="fetchCompanies()"
        @update:items-per-page="fetchCompanies()"
        @click:row="openItem"
        show-select
        flat
        :single-select="false"
      >
        <template #item.company="{ item }">
          <v-avatar color="primary" size="30" class="mr-2">
            <img v-if="item.profile_img" :src="item.profile_img" />
          </v-avatar>
          {{ item.company }}
        </template>
        <template #item.plan="{ item }">
          <v-chip
            v-if="item.plan"
            class="pl-5 pr-5"
            :color="
              plans[item.plan.plan_slug] ? plans[item.plan.plan_slug].color : ''
            "
            :text-color="
              plans[item.plan.plan_slug] ? plans[item.plan.plan_slug].text : ''
            "
          >
            {{ item.plan.name }}
          </v-chip>
        </template>
        <template #item.branche="{ item }">
          <span>
            {{ idToString("JOB_BRANCHE", item.branche) }}
          </span>
        </template>
      </v-data-table>
    </v-app>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ $t("deleteThisItem") }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t("ok")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteSelected" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ $t("deleteThisItem") }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteSelected">{{
            $t("cancel")
          }}</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteSelectedItemConfirm"
            >{{ $t("ok") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "CompanyManagementTable",

  data() {
    return {
      params: {
        page: 1,
        per_page: 10,
        user_type: "Employer",
      },
      dialog: false,
      dialogDelete: false,
      dialogDeleteSelected: false,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: this.$t("company"),
          value: "company",
          width: "25%",
        },
        {
          text: this.$t("subscriptionPlan"),
          value: "plan",
          width: "20%",
        },
        {
          text: this.$t("address"),
          value: "address",
          width: "20%",
        },
        {
          text: this.$t("branch"),
          value: "branche",
          width: "20%",
        },
        {
          text: this.$t("employees"),
          value: "company_employees",
          width: "20%",
        },
      ],
      plans: {
        basic: {
          color: "#F2E7FC",
          text: "#8C18E2",
        },
        "all-inclusive": {
          color: "#E1F5FD",
          text: "#4A4DE6",
        },
        premium: {
          color: "#FBEDE7",
          text: "#FF6422",
        },
      },
    };
  },

  created() {
    this.fetchCompanies();
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editing = false;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDeleteSelected() {
      this.dialogDeleteSelected = false;
    },
    removeSelected: function() {
      this.dialogDeleteSelected = true;
    },
    deleteItemConfirm() {
      store.dispatch("admin/companyDeleteListItem", {
        item: this.editedItem,
        params: this.params,
      });
      this.closeDelete();
    },
    deleteSelectedItemConfirm() {
      let selected = [];
      this.selected.map((e) => {
        selected.push(e.id);
      });

      store.dispatch("admin/companyDeleteSelectedListItem", {
        items: selected,
        params: this.params,
      });

      this.closeDeleteSelected();
    },
    fetchCompanies() {
      store.dispatch("admin/fetchCompanies", this.params);
    },
    openItem(item) {
      this.$router.push({
        name: "AdminCompanyManagementUser",
        params: { id: item.id },
      });
    },
  },
  watch: {
    dialog(val) {
      alert(this.selected);
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDeleteSelected(val) {
      val || this.closeDeleteSelected();
    },
  },
};
</script>

<style lang="scss" scoped>
// .company-management-table {
//   .v-data-table {
//     background: transparent;
//     table {
//       border-spacing: 0 20px !important;
//     }
//   }
//   .v-data-table table {
//     border-spacing: 0 20px !important;
//   }
// }
</style>
